import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    //redirect: '/sinoepc-weifang'
    redirect: '/sinoepc-weifang-index'

  },
  {
    name: 'user',
    component: () => import('./view/user'),
    meta: {
       title: '会员中心'
    }
  },
  {
    name: 'coupon/:token',
    component: () => import('./view/coupon/index'),
    meta: {
        title: '领券中心'
    }
  },
  {
    name: 'user1',
    component: () => import('./view/coupon/index1'),
    meta: {
        title: '周一领券中心'
    }
  },
  {
    name: 'user2',
    component: () => import('./view/coupon/index2'),
    meta: {
    title: '周二领券中心'
    }
  },
  {
      name: 'user3',
      component: () => import('./view/coupon/index3'),
      meta: {
        title: '周三领券中心'
      }
  },
  {
      name: 'user4',
      component: () => import('./view/coupon/index4'),
      meta: {
        title: '周四领券中心'
    }
  },
  {
      name: 'user5',
      component: () => import('./view/coupon/index5'),
      meta: {
          title: '周五领券中心'
      }
  },
  {
      name: 'user6',
      component: () => import('./view/coupon/index6'),
      meta: {
        title: '周六领券中心'
       }
  },
  {
      name: 'user7',
      component: () => import('./view/coupon/index7'),
      meta: {
        title: '周日领券中心'
      }
  },
  {
      name: 'user8/:phone',
      component: () => import('./view/coupon/index8'),
      meta: {
        title: '测试领券中心'
   }
  },
    {
      name: 'pay',
      component: () => import('./view/pay'),
      meta: {
        title: '银联支付'
      }
    },
    {
      name: 'pay-cmcc',
      component: () => import('./view/pay/cmcc'),
      meta: {
        title: '银联支付'
      }
    },
    {
      name: 'pay-error',
      component: () => import('./view/pay/error'),
      meta: {
        title: '支付'
      }
    },
    {
      name: 'grant-mobile',
      component: () => import('./view/grant/mobile'),
      meta: {
        title: '测试手机号授权'
      }
    },
    //Consumer Voucher)
   {
      name: 'voucher-zaozhuang',
      component: () => import('./view/voucher/zaozhuang'),
      meta: {
        title: '山亭区惠民消费券'
      }
    },
   {
      name: 'sinoepc-weifang',
      component: () => import('./view/sinoepc/weifang'),
      meta: {
        title: '潍坊政府消费券'
      }
    },
   {
      name: 'sinoepc-weifang-index',
      component: () => import('./view/sinoepc/weifang/index'),
      meta: {
        title: '潍坊政府消费券'
      }
    },

   {
      name: 'sinoepc-weifang/:model',
      component: () => import('./view/sinoepc/weifang/detail'),
      meta: {
        title: '潍坊政府消费券'
      }
    },
  /* {
      name: 'sinoepc-weifang-qingzhou',
      component: () => import('./view/sinoepc/weifang/qingzhou'),
      meta: {
        model: 'qingzhou',
        title: '惠享青州消费券',
        couponId: 37070001,
      }
    },
   {
      name: 'sinoepc-weifang-linqu',
      component: () => import('./view/sinoepc/weifang/detail'),
      meta: {
        model: 'linqu',
        title: '惠享临朐消费券',
        couponId: 37070001,
      }
    },*/
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  mode: 'history', // 去掉#
  routes
});


router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
if (title) {
  document.title = title;
}
next();
});

export {
  router
};
