import Vue from 'vue'
import App from './App.vue'
import { router } from './router';

// 云闪付授权组件
import VueCupUi from 'vue-cup-ui';
import 'vue-cup-ui/lib/vue-cup-ui.css';
Vue.use(VueCupUi);

import 'vant/lib/index.css';

Vue.prototype.$getViewportSize = function(){
  return {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,//兼容性获取屏幕宽度
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight//兼容性获取屏幕高度
  };
};

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

